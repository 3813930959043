<template>
  <div class="hello">
    <v-snackbar v-model="snackbar">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="snackbar = false">
            Close
          </v-btn>
        </template>
    </v-snackbar>

    <h1>{{ msg }}</h1>
    <div>
    <p>To use this app, please connect your Web3 wallet by clicking on your provider's icon below.</p>
    </div>
    <div class="flex">
      <button @click="gamestop">
        <img
          src="https://lh3.googleusercontent.com/eHzDzu3Av35lAs1DdePjVkHADOei8YGAvMlRyol1eK9okecUOYkn8cpeYUgiBM4Qy4sTmgFMLnSRujzJvIX3rH5rJlE=w128-h128-e365-rj-sc0x00ffffff"
          alt="Gamestop"
          height="36"
        />
      </button>
      <button @click="metaMask">
        <img
          src="https://static.loopring.io/assets/svg/meta-mask.svg"
          alt="MetaMask"
          height="36"
        />
      </button>
      <!--
      <button @click="walletConnect">
        <img
          src="https://static.loopring.io/assets/svg/wallet-connect.svg"
          alt="walletConnect"
          height="36"
        />
      </button>
      -->
      <button @click="disconnect">disconnect</button>
    </div>
    <div>
      <h2>What's a wallet?</h2>
      <p>A <a href="https://support.blockchain.gamestop.com/hc/en-us/articles/4784146609555-What-is-a-browser-extension-wallet-">wallet</a> is where you hold your crypto assets and allows you to interact with distributed apps (dApps) like this one!</p>
      <p>If you don't have a wallet, we recommend the <a href="https://wallet.gamestop.com/">GameStop Wallet</a> which has a great <a href="https://support.blockchain.gamestop.com/hc/en-us/articles/4412264969363-How-to-Create-a-Wallet">setup guide</a>.</p>
    </div>
  </div>
</template>

<script>
import { walletServices, connectProvides } from "@loopring-web/web3-provider";
import { Subscription } from "rxjs";
export default {
  name: "HelloWorld",
  props: {
    sender: {
      default: "Plot",
      type: String,
    },
  },
  data() {
    return {
      msg: "Connect your wallet",
      snackbar: false,
      text: `You don't have that wallet installed!`,    };
  },
  subscription: Subscription,
  methods: {
    handleError: function(data) {
      this.snackbar = true;
      //console.log("handleError", data);
    },
    handleProcessing: function(data) {
      this.snackbar = true;
    },
    handleAccountDisconnect: function() {
    },
    handleConnect(accounts, provider, chainId) {
      //console.log(accounts, provider, chainId);
      this.$router.push({
            name: this.sender,
            params: {
                act: accounts,
                prov: provider,
                chain: chainId
            }
        });
    },
    metaMask: async () => {
      walletServices.sendDisconnect("", "should new provider");
      await connectProvides.MetaMask();
    },
    walletConnect: async () => {
      walletServices.sendDisconnect("", "should new provider");
      await connectProvides.WalletConnect({ account: -1, darkMode: true });
    },
    gamestop: async () => {
      walletServices.sendDisconnect("", "should new provider");
      await connectProvides.GameStop();
    },
    disconnect: () => {
      walletServices.sendDisconnect("", "disconnect");
    },
  },
  async beforeMount() {
    const subject = walletServices.onSocket();
    this.subscription = subject.subscribe(({ data, status }) => {
      switch (status) {
        case "Error":
          this.handleError(data);
          break;
        case "Processing":
          this.handleProcessing(data);
          break;
        case "ConnectWallet": // provider, accounts, chainId, networkId
          const { accounts, provider, chainId } = data
            ? data
            : {
                accounts: undefined,
                provider: undefined,
                chainId: 1,
              };
          this.handleConnect(accounts, provider, chainId);
          break;
        case "DisConnect":
          this.handleAccountDisconnect();
          break;
        default:
      }
    });
  },
  async beforeDestroy() {
    this.subscription.unsubscribe();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.flex {
  display: flex;
  flex-direction: column;
}
button {
  margin-top: 10px;
}
</style>
